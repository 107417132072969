import React from "react"
import { useStore } from "../../../../context"
import { dispatchKeyword } from "../../../../context/middleware"
import { getTechStack } from "../../../../helpers"

const CategoriesList = () => {
  const [_, dispatch] = useStore((store) => store.currentPage)
  
  const techStack = getTechStack()

  const onClickProject = (e: React.MouseEvent, keyword: string) => {
    e.preventDefault()
    
    dispatchKeyword(keyword, dispatch)
  }
  
  return (
    <div className="mb-8 md:mb-0">
        <h2 className="text-xl font-bold mb-4">Libraries & Languages</h2>
        <ul className="md:block">
          <li className="mb-2 inline-block md:block ml-5 mr-5 md:ml-0 md:mr-0 hover:text-indigo-600">
            <a href={`/projects`} onClick={(e) => onClickProject(e, '')}>All</a>
          </li>
          {techStack.map((techInfo, index) => (
            <li key={index} className="mb-2 inline-block md:block ml-5 mr-5 md:ml-0 md:mr-0 hover:text-indigo-600">
              <a href={`/projects?skill=${techInfo.name.toLowerCase()}`} onClick={(e) => onClickProject(e, techInfo.name.toLowerCase())}>{techInfo.name} ({techInfo.projectCount})</a>
            </li>
          ))}
        </ul>
    </div>
  )
}

export default CategoriesList
