export enum EPage {
    Homepage,
    Resume,
    Package_UseSound,
    Projects,
    Contacts
}

export type TAppState = {
    currentPage: EPage
}
