import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const ProhanFood = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'prohan-food-app')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{project.title}</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                Prohan Food is a small test application that helps you quickly order food with just 5 clicks on an app. It utilizes Codeigniter (PHP) as the backend and Firebase Cloud Messaging for push notifications.
                            </p>
                            <br /><br />
                            <div>
                                <img
                                    src={require('../../assets/projects/prohan-food/screenshot_2.jpg')}
                                    alt="Product detail screen"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/prohan-food/screenshot_3.jpg')}
                                    alt="Cart Screen"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/prohan-food/screenshot_4.jpg')}
                                    alt="Login screen"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                            <br />
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <img
                            src={require('../../assets/projects/prohan-food/screenshot_1.jpg')}
                            alt="Prohan Food App"
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProhanFood
