import { TStore } from ".";
import { contents } from "../contents";
import { EPage } from "../types";

export function dispatchKeyword(keyword: string, dispatch: (value: Partial<TStore>) => void) {
    const filteredPage = contents.filter((item) => {
        const filteredSkills = item.techStack.filter((techSkill) => techSkill.toLowerCase().indexOf(keyword) >= 0)
        return filteredSkills.length > 0
    })
    
    let url = '/projects'
    if (keyword !== '') {
        url += `?skill=${keyword}`
    }

    window.history.pushState({}, '', url)

    let dispatchValue: Partial<TStore> = {
        keyword: keyword,
        pages: filteredPage
    }
    const isInProjectDetail = window.location.href.indexOf('/project/') >= 0
    if (isInProjectDetail) {
        dispatchValue.currentPage = EPage.Projects
    }

    dispatch(dispatchValue)
}
