import SkillItem from "../../../../components/SkillItem"

interface IBlogItemProps {
    title: string
    slug: string
    techStack: string[]
    description: string
    thumbnail: string
    createdDate: string
}

const BlogItem = (props: IBlogItemProps) => {
    const { title, description, slug, thumbnail, techStack, createdDate } = props
    return (
        <div className="flex flex-col md:flex-row mb-8 border-b pb-4">
            <div className="md:w-1/4 pr-4 mb-4 md:mb-0">
                <img src={thumbnail} alt="Thumbnail" className="w-full h-auto rounded-md" />
            </div>
            <div className="md:w-3/4">
                <a href={`/project/${slug}`} className="text-xl font-bold mb-2">{title}</a>
                <p className="text-gray-600 mb-2">{description}</p>
                <div className="mb-4">
                    {techStack.map((item, index: number) => {
                        return (
                            <SkillItem key={`landl-${slug}-${index}`} label={item} isLockAssign={true} />
                        )
                    })}
                </div>
                <p className="text-sm text-gray-800">{new Date(createdDate).toDateString()}</p>
            </div>
        </div>
    )
}

export default BlogItem
