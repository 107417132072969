import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { contents, TContentPageItem } from "../contents"
import { EPage } from "../types"

export type TStore = {
    currentPage: EPage
    keyword: string
    pages: TContentPageItem[]
}

export function useStoreData(): {
    get: () => TStore
    set: (_: Partial<TStore>) => void
    subscribe: (callback: () => void) => () => void
} {
    const store = useRef({
        currentPage: EPage.Homepage,
        keyword: '',
        pages: contents
    })
    
    const subscribers = useRef(new Set<() => void>())

    const get = useCallback(() => store.current, [])

    const set = useCallback((value: Partial<TStore>) => {
        store.current = { ...store.current, ...value }

        subscribers.current.forEach((callback) => callback())
    }, [])

    const subscribe = useCallback((callback: () => void) => {
        subscribers.current.add(callback)

        return () => subscribers.current.delete(callback)
    }, [])

    return {
        get,
        set,
        subscribe
    }
}

export type TStoreData = ReturnType<typeof useStoreData>
export const AppContext = createContext<TStoreData | null>(null)

export function ThienTDProvider({ children }: { children: React.ReactNode }) {
    return (
        <AppContext.Provider value={useStoreData()}>
            {children}
        </AppContext.Provider>
    )
}

export function useStore<SelectorOutput>(
    selector: (store: TStore) => SelectorOutput
): [SelectorOutput, (value: Partial<TStore>) => void] {
    const store = useContext(AppContext)!
    if (!store) {
        console.log('Error')
    }

    const [state, setState] = useState(() => selector(store.get()))

    useEffect(() => {
        const updateState = () => {
            setState(selector(store.get()))
        }
        const unsubscribe = store.subscribe(updateState)
        return () => unsubscribe()
    }, [])

    return [state, store.set]
}