import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const TpnrpTeamDeathMatch = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'esx-tpnrp-teamdeathmatch')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">TPNRP Team Death Match - FiveM resources</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                TPNRP Team Death Match is a team-based shooting mode designed for GTA Roleplay servers (FiveM). Inspired by the UI of CS:GO.
                            </p>
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/esx_tpnrp_teamdeathmatch/screenshot_1.jpg')}
                                    alt="Screenshot"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                                <p className="text-gray-600 text-center">The user interface design is inspired by CS:GO.</p>
                            </div>
                            <br />
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/esx_tpnrp_teamdeathmatch/screenshot_2.jpg')}
                                    alt="Screenshot"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                                <p className="text-gray-600 text-center">Winning Team Announcement.</p>
                            </div>
                            <br />
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/esx_tpnrp_teamdeathmatch/screenshot_3.jpg')}
                                    alt="Screenshot"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                                <p className="text-gray-600 text-center">A simple scoreboard. The weapon wheel is inspired by GTA 5.</p>
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                The project, hosted on GitHub at <a className="text-indigo-600" href='https://github.com/Sn0wBiT/esx_tpnrp_teamdeathmatch' target='_blank' rel="noreferrer">https://github.com/Sn0wBiT/esx_tpnrp_teamdeathmatch</a>, has garnered attention with <b>11 stars</b> and <b>13 forks</b>, attesting to its popularity and community support since its inception on September 06, 2020.
                            </p>
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <img
                            src={require('../../assets/projects/esx_tpnrp_teamdeathmatch/lua_code.png')}
                            alt="Server Side Lua"
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TpnrpTeamDeathMatch
