import { useEffect } from "react"
import { useStore } from "../../context"
import { dispatchKeyword } from "../../context/middleware"
import { useQuery } from "../../hooks/useQuery"
import CategoriesList from "./components/Categories"
import ProjectList from "./components/ProjectList"
import SearchInput from "./components/SearchInput"

const ProjectPage = () => {
	const query = useQuery()
	const [_, dispatch] = useStore((store) => store.currentPage)

	useEffect(() => {
		const skillQuery = query.get('skill')
		if (!skillQuery) {
			return
		}
		
		dispatchKeyword(skillQuery, dispatch)
	}, [])
	
    return (
		<div className="flex flex-col md:flex-row">
			<div className="md:w-1/4 p-4">
				<CategoriesList />
			</div>
			<div className="md:w-3/4 p-4">
				<SearchInput />
				<ProjectList />
			</div>
		</div>
    )
}

export default ProjectPage
