import { ReactNode } from "react"
import HupersApp from "./components/HupersApp"
import ThienSangWedding from "./components/ThienSangWedding"
import TpnrpLuckyWheel from "./components/TpnrpLuckyWheel"
import TpnrpTeamDeathMatch from "./components/TpnrpTeamDeathMatch"
import WebisAlarm from "./components/WebisAlarm"
import ProhanFood from "./components/ProhanFood"
import VscodeVnwExtension from "./components/VscodeVnwExtension"
import SkingekiNoKyojin from "./components/ShingekiNoKyojin"
import SpecialForce2Clone from "./components/SpecialForce2Clone"
import VnwPageSpeedInsights from "./components/VnwPageSpeedInsights"

// TODO: contents holder
export type TContentPageItem = {
    slug: string
    techStack: string[]
    content: ReactNode
    createdDate: string
    title: string
    shortDescription: string
    thumbnail: any
}

export const contents: TContentPageItem[] = [
    {
        slug: "vietnamworks-page-speed-insights",
        techStack: ['Typescript', 'NodeJS', 'Strapi', 'ReactJS', 'VSCode', 'Vite'],
        content: <VnwPageSpeedInsights />,
        createdDate: "2024-04-02",
        title: "VietnamWorks Page Speed Insights",
        shortDescription: "A tools for monitor page speed insights daily to keep tracking performance, SEO.",
        thumbnail: require('../assets/projects/vnw-page-speed/thumbnail.png')
    },
    {
        slug: "vscode-vietnamworks-extension",
        techStack: ['Typescript', 'Javascript', 'ReactJS', 'VSCode'],
        content: <VscodeVnwExtension />,
        createdDate: "2024-03-23",
        title: "VietnamWorks Extension for VSCode",
        shortDescription: "A simple Visual Studio Code extension that supports creating merge requests quickly. It allows users to easily create merge requests from the 'develop' branch to the 'release' branch, as well as from the current branch to the 'develop' branch.",
        thumbnail: require('../assets/projects/vscode-vietnamworks-extension/vscode-vnw-extension-thumbnail.png')
    },
    {
        slug: 'thien-sang-wedding',
        title: 'My Wedding \'Attendance\' Project',
        shortDescription: 'This is a Wedding Attendance Portal for my wedding! It allows guests to seamlessly provide essential details such as dietary preferences and allergies. In another way, it helps me to count how many guests will attend my wedding.',
        thumbnail: require('../assets/projects/wedding/supabase_database_thumbnail.png'),
        techStack: ['Supabase', 'Firebase Hosting', 'ReactJS', 'Javascript', 'Typescript', 'AMP-Story'],
        content: <ThienSangWedding />,
        createdDate: '2023-03-04'
    },
    {
        slug: 'hupers-app',
        title: 'Hupers App - A realtime chat app',
        shortDescription: 'Hupers App is a real-time chat application that utilizes Socket.IO for live chat functionality, Firebase Cloud Messaging for receiving notifications, and WebRTC for real-time video calls.',
        thumbnail: require('../assets/projects/hupers/hupers_app_thumbnail.png'),
        techStack: ['Flutter', 'Dart', 'NodeJS', 'Javascript', 'Socket.io', 'WebRTC', 'MySQL', 'Firebase Cloud Messaging'],
        content: <HupersApp />,
        createdDate: '2021-02-01'
    },
    {
        slug: 'esx-tpnrp-teamdeathmatch',
        title: 'TPNRP Team Death Match - FiveM resources',
        shortDescription: 'TPNRP Team Death Match is a team-based shooting mode designed for GTA Roleplay servers (FiveM). Inspired by the UI of CS:GO.',
        thumbnail: require('../assets/projects/esx_tpnrp_teamdeathmatch/thumbnail.png'),
        techStack: ['HTML', 'CSS', 'Javascript', 'Lua', 'MySQL', 'FiveM'],
        content: <TpnrpTeamDeathMatch />,
        createdDate: '2020-09-06'
    },
    {
        slug: 'esx-tpnrp-luckywheel',
        title: 'TPNRP Lucky Wheel - FiveM resources',
        shortDescription: 'The TPNRP Lucky Wheel is a resource for FiveM. It functions as a plugin for the FiveM server game and utilizes the default Lucky Wheel in GTA 5.',
        thumbnail: require('../assets/projects/esx_tpnrp_luckywheel/thumbnail.png'),
        techStack: ['HTML', 'CSS', 'Javascript', 'Lua', 'MySQL', 'FiveM'],
        content: <TpnrpLuckyWheel />,
        createdDate: '2019-08-27'
    },
    {
        slug: 'prohan-food-app',
        title: 'Prohan Food App - Online market',
        shortDescription: 'Prohan Food is a small test application that helps you quickly order food with just 5 clicks on an app. It utilizes Codeigniter (PHP) as the backend and Firebase Cloud Messaging for push notifications.',
        thumbnail: require('../assets/projects/prohan-food/thumbnail.png'),
        techStack: ['Flutter', 'Dart', 'PHP', 'Codeigniter', 'MySQL', 'Firebase Cloud Messaging'],
        content: <ProhanFood />,
        createdDate: '2021-02-01'
    },
    {
        slug: 'special-force-2-clone-game',
        title: 'Special Force 2 - Cloned Game',
        shortDescription: 'This is cloned game from Special Force 2 game. All assets belong to Dragonfly GF Co., LTD.',
        thumbnail: require('../assets/projects/special-force-2-clone/thumbnail.png'),
        techStack: ['C#', 'Unity3D', 'Game'],
        content: <SpecialForce2Clone />,
        createdDate: '2018-12-23'
    },
    {
        slug: 'webis-alarm',
        title: 'Webis Alarm - A task notification application',
        shortDescription: 'Webis Alarm is an application used to receive notifications from my previous company.',
        thumbnail: require('../assets/projects/webis_alarm/thumbnail.png'),
        techStack: ['HTML', 'CSS', 'Javascript', 'C#', 'CefSharp', 'Semantic-UI'],
        content: <WebisAlarm />,
        createdDate: '2017-03-27'
    },
    {
        slug: 'shingeki-no-kyojin-fan-game',
        title: 'Shingeki No Kyojin Fan Game',
        shortDescription: 'Base on "Attack on Titan" anime.',
        thumbnail: require('../assets/projects/shingeki-no-kyojin-fan-game/thumbnail.png'),
        techStack: ['C#', 'Unity3D', 'Proton', 'Game'],
        content: <SkingekiNoKyojin />,
        createdDate: '2015-10-11'
    },
]