import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const ThienSangWedding = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'thien-sang-wedding')
    const project = filteredProjects[0]
    
    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">My Wedding Attendance</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                In early 2023, I embarked on the journey of creating a website to introduce my wedding. The site incorporates a unique feature for attendance checking, as my wedding doesn't follow a traditional table booking system; instead, it is based on the number of guests attending. To facilitate this, I developed a comprehensive wedding attendance system that encompasses various elements.
                            </p>
                            <div>
                                <img
                                    src={require('../../assets/projects/wedding/thien_sang_wedding_intro.png')}
                                    alt="thiensangwedding.com"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                The initial steps involved securing a domain and hosting. I acquired the domain name <a className="text-indigo-600" href="https://thiensangwedding.com" target='_blank' rel="noreferrer">https://thiensangwedding.com</a> (It is already closed. You can found a web archive version here <a className="text-indigo-600" href="https://thientd.dev/thien-sang-wedding/" target='_blank' rel="noreferrer">https://thientd.dev/thien-sang-wedding/</a>) and opted for Firebase Hosting to manage costs effectively.<br /><br /> With the domain and hosting successfully set up, the main frontend of the website was crafted using HTML, JavaScript, and CSS. The attendance form, crucial for guest tracking, was implemented using ReactJS with TypeScript for enhanced functionality.
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                On the backend side, I employed Supabase Functions to handle API functions and leveraged Supabase Database to store and manage guest information. This combination of technologies ensures a seamless and efficient experience for both myself and my wedding guests.
                            </p>
                            
                        </div>
                    </div>
                    <video className="rounded-xl shadow-xl ring-1 ring-gray-400/10" controls>
                        <source src={require('../../assets/videos/unboxing_thiensangwedding_card.mp4')} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <br />
                <div className="flex items-center w-full justify-center flex-col">
                    <img
                        src={require('../../assets/projects/wedding/supabase_database.png')}
                        alt="Guests notes"
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        width={2432}
                        height={1442}
                    />
                    <p className="text-gray-600">Guest notes</p>
                </div>
            </div>
        </div>
    )
}

export default ThienSangWedding
