import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const VnwPageSpeedInsights = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'vietnamworks-page-speed-insights')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">VietnamWorks Page Speed Insights</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                            VietnamWorks Page Speed Insights is a tool designed for daily monitoring of page speed to track website performance and SEO metrics.
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                            The backend is powered by Strapi, which handles API management and scheduling through cron jobs, while the frontend is built using React and Vite, ensuring a fast and efficient user interface.
                            </p>
                            
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/vnw-page-speed/page_speeds.png')}
                                    alt="List of each analyzis runtime"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                            <br />
                            <div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <img
                            src={require('../../assets/projects/vnw-page-speed/dashboard.png')}
                            alt="VietnamWorks VSCode Extension"
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={2432}
                            height={1442}
                        />
                        <br />
                        <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                            It utilizes Google Lighthouse <a href="https://github.com/GoogleChrome/lighthouse" target="_blank" rel="noreferrer">(https://github.com/GoogleChrome/lighthouse)</a> to present detailed analysis data.
                        </p>
                        <br />
                        <img
                            src={require('../../assets/projects/vnw-page-speed/detail.png')}
                            alt="Detail of pagespeed"
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VnwPageSpeedInsights
