import { Outlet } from 'react-router-dom'
import NavBar from './components/NavBar'
import { ThienTDProvider } from './context'

export default function App() {
  return (
    <ThienTDProvider>
      <div className="min-h-full">
        <NavBar />
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">{/* Your content */}
            <Outlet />
          </div>
        </main>
      </div>
    </ThienTDProvider>
  )
}
