
const ResumePage = () => {

    const btnClass = "inline-block px-3 py-1.5 text-md text-white duration-150 bg-indigo-600 rounded-lg hover:bg-indigo-700 active:shadow-lg mt-8"

    return (
        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
            <div className="absolute inset-0 -z-10 overflow-hidden">
              <svg
                className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                    width={200}
                    height={200}
                    x="50%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                  <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                  />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
              </svg>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
              <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div className="lg:pr-4">
                  <div className="lg:max-w-lg">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Summary</h1>
                    <div className="mt-6 text-xl leading-8 text-gray-700">
                        <p>I am a dedicated frontend developer with a diverse skill set that includes expertise in JavaScript, TypeScript, PHP, MySQL, Dart, and C#.</p>
                        <a className={btnClass} download href={require('../../assets/Tran_Du_Thien_Frontend_Developer.pdf')}>Get My Resume</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                <img
                  className="w-full max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
                  src={require('../../assets/images/cv_preview.png')}
                  alt=""
                />
              </div>
              <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div className="lg:pr-4">
                  <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                    <p>
                        Additionally, I am proficient in web technologies like HTML5 and CSS, and have hands-on experience with popular frontend libraries and frameworks such as ReactJS, NextJS, and NodeJS.
                    </p>
                    <p className="mt-6">
                        My versatility extends to mobile app development using Flutter, and I have worked with backend technologies like Codeigniter, Laravel, and Strapi. I am passionate about creating engaging and responsive user interfaces, and I thrive in collaborative environments that value innovation and clean code practices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}

export default ResumePage
