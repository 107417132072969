import { Link, useParams } from "react-router-dom"
import { contents } from "../../../../contents"
import { useEffect } from "react"

const ProjectDetail = () => {
    const { projectSlug } = useParams()
    const filteredProjects = contents.filter((item) => item.slug === projectSlug)
    
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        
        // Set the script's source
        script.src = 'https://utteranc.es/client.js'
        
        script.setAttribute('repo', 'Sn0wBiT/thientd-blog')
        script.setAttribute('issue-term', window.location.href)
        script.setAttribute('theme', 'github-light')
        script.setAttribute('crossOrigin', 'anonymous')
        script.setAttribute('async', 'true')
        
        // Append the script to the document head
        
        const utterancesWrapper = document.querySelector('.utterances-wrapper')
        utterancesWrapper?.appendChild(script)
        // Return a cleanup function to remove the script when the component unmounts
        return () => {
            const utterancesWrapper = document.querySelector('.utterances-wrapper')
            utterancesWrapper?.removeChild(script)
        }
    }, [])

    if (filteredProjects.length === 0) {
        window.location.assign('/404')
        return <></>
    }

    return (
        <>
            <nav className="bg-gray-100 p-4 rounded-md">
            <ol className="list-none p-0 inline-flex">
                <li className="flex items-center">
                    <Link to='/'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                            <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                        </svg>
                    </Link>
                </li>
                <li className="flex items-center">
                    <span className="mx-2">/</span>
                    <Link to='/projects' className="text-blue-600">Projects</Link>
                </li>
                <li className="flex items-center">
                    <span className="mx-2">/</span>
                    <span >{filteredProjects[0].title}</span>
                </li>
            </ol>
            </nav>
            <div className="" data-cname="detail">
                {filteredProjects[0].content}
            </div>
            <div className="overflow-hidden bg-white py-4 sm:py-4 rounded-md utterances-wrapper" />
        </>
    )
}

export default ProjectDetail
