import { useStore } from "../../../../context"
import { dispatchKeyword } from "../../../../context/middleware"
import BlogItem from "../BlogItem"

const ProjectList = () => {
    const [keyword, dispatch] = useStore((store) => store.keyword)
    const [pages] = useStore((store) => store.pages)

	const onClickReset = (e: React.MouseEvent) => {
		e.preventDefault()

        dispatchKeyword('', dispatch)
	}

    
    return (
        <>
            {keyword !== '' && pages.length > 0 && (
					<div>
						<p className="mb-6">Search result: "{keyword}" <b>({pages.length})</b></p>
					</div>
				)}
				{pages.map((item, index) => (
					<BlogItem
						key={`${item.slug}-${index}`}
						title={item.title}
						slug={item.slug}
						description={item.shortDescription}
						thumbnail={item.thumbnail}
						createdDate={item.createdDate}
						techStack={item.techStack}
					/>
				))}
				{pages.length === 0 && (
					<div className="text-center">
						<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">No result found</h1>
						<p className="mt-6 text-base leading-7 text-gray-600">Sorry, no results found. I couldn’t find the result you’re looking for.</p>
						<div className="mt-10 flex items-center justify-center gap-x-6">
							<button onClick={onClickReset} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
								Reset search term
							</button>
						</div>
					</div>
				)}
        </>
    )
}

export default ProjectList
