import { Disclosure } from "@headlessui/react"
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import { useStore } from "../../context"
import { EPage } from "../../types"
import GitHubIcon from "../Icons/GitHubIcon"
  
const NavBar = () => {
    const [currentPage, dispatch] = useStore((store) => store.currentPage)

    const navigation = [
        { name: 'Work history', href: '/', pageName: EPage.Homepage },
        { name: 'Resume', href: '/resume', pageName: EPage.Resume },
        { name: 'Projects', href: '/projects', pageName: EPage.Projects },
        // { name: 'Contacts', href: '/contacts', pageName: EPage.Contacts },
        // { name: 'Packages', href: '/packages', pageName: EPage.Package_UseSound }
    ]

    useEffect(() => {
        const filteredItem = navigation?.filter((item) => item.href === window.location.pathname)
        dispatch({
            currentPage: filteredItem.length === 0 ? EPage.Projects : filteredItem[0].pageName
        })
    }, [])

    return (
        <>
            <Disclosure as="nav" className="bg-gray-800">
                {({ open }) => (
                    <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <a href='/'>
                                        <img
                                            className="h-8 w-8 rounded-md"
                                            src={require('../../assets/images/avatar_1.png')}
                                            alt="ThienTD"
                                        />
                                    </a>
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-10 flex items-baseline space-x-4">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className={`${currentPage === item.pageName
                                                ? 'bg-gray-900 text-white'
                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white'} rounded-md px-3 py-2 text-sm font-medium`}
                                                aria-current={currentPage === item.pageName ? 'page' : undefined}
                                                onClick={() => dispatch({
                                                    currentPage: item.pageName
                                                })}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="-mr-2 flex md:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                ) : (
                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                )}
                                </Disclosure.Button>
                            </div>
                            <div className="inset-y-0 right-0 flex hidden md:block items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* Github */}
                                <a
                                    href='https://github.com/sn0wBiT'
                                    className="ml-3 relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" 
                                    style={{ width: 24, height: 24}}
                                    target="_blank" rel="noreferrer"
                                >
                                    <GitHubIcon />
                                </a>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className={`${currentPage === item.pageName ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'} block rounded-md px-3 py-2 text-base font-medium`}
                                aria-current={currentPage === item.pageName ? 'page' : undefined}
                                >
                                {item.name}
                                </Disclosure.Button>
                            ))}
                            <a
                                    href='https://github.com/sn0wBiT'
                                    className="ml-3 relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" 
                                    style={{ width: 24, height: 24}}
                                    target="_blank" rel="noreferrer"
                                >
                                    <GitHubIcon />
                                </a>
                        </div>
                        <div className="border-t border-gray-700 pb-3 pt-4">
                        
                        </div>
                    </Disclosure.Panel>
                    </>
                )}
                </Disclosure>

        </>
    )
}

export default NavBar
