import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const HupersApp = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'hupers-app')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Hupers App</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                Hupers App is an innovative real-time chat application designed to deliver seamless communication experiences. Leveraging the power of Socket.IO for live chat functionality, Firebase Cloud Messaging for instant notifications, and WebRTC for immersive real-time video calls, Hupers ensures a dynamic and engaging user interaction.
                                <br /><br />
                                The backend is powered by Node.js, employing WebRTC for efficient communication, while the frontend is developed using Flutter and Dart, providing a cross-platform and visually appealing user interface.
                            </p>
                            <br />
                            <div>
                                <video controls>
                                    <source src={require('../../assets/projects/hupers/hupers_app_demo.mp4')} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                This combination of cutting-edge technologies enables Hupers to offer a robust and feature-rich chat platform, meeting the demands of modern communication.
                            </p>
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <img
                            src={require('../../assets/projects/hupers/huper_app_screenshot_1.PNG')}
                            alt="Hupers App"
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HupersApp
