const Homepage = () => {
    return (
        <>
          <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
            <div className="absolute inset-0 -z-10 overflow-hidden">
              <svg
                className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                    width={200}
                    height={200}
                    x="50%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                  <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                  />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
              </svg>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
              <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div className="lg:pr-4">
                  <div className="lg:max-w-lg">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Work History</h1>
                  </div>
                </div>
              </div>
              <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                <a href="/resume">
                  <img
                    className="w-full max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
                    src={require('../../assets/images/cv_preview.png')}
                    alt=""
                  />
                </a>
              </div>
              <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div className="lg:pr-4">
                  <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                    <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Navigos Group</h2>
                    <p className="font-semibold">
                      Frontend Developer
                    </p>
                    <p className="font-semibold">
                      September 2021 - Present, Ho Chi Minh City
                    </p>
                    <p className="mt-6">
                      As an Agile Frontend Developer at Navigos Group, I spearhead the development and maintenance of VietnamWorks websites, focusing on crafting seamless user experiences. My role involves collaborating within cross-functional teams, shaping the frontend using a blend of HTML, CSS, and JavaScript, coupled with backend integrations.
                    </p>
                    <p className="mt-8">
                      <strong className="font-semibold text-gray-900">Key Contributions:</strong>
                    </p>
                    <ul className="mt-2 space-y-4 text-gray-600">
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Collaborating within interdisciplinary teams to ideate and implement effective solutions.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Defining and integrating frontend functionalities while championing a user-centric approach.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Translating designs into responsive, interactive web applications across multiple devices.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Elevating static pages into dynamic micro apps, leveraging animations and collaborative APIs for enhanced user engagement.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Maintaining and enhancing legacy frontend functionalities, ensuring usability and resolving bugs.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Actively participating in Scrum ceremonies and conducting ongoing research to implement cutting-edge frontend technologies, optimizing user experiences.
                        </span>
                      </li>
                    </ul>
                    <p className="mt-8">
                      <strong className="font-semibold text-gray-900">Skills:</strong>
                    </p>
                    <ul className="mt-2 space-y-4 text-gray-600">
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Proficiency in ES6 and JavaScript frameworks such as React and Redux.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Hands-on experience in SASS, Styled Components, and Modular CSS.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> In-depth knowledge of cross-browser compatibility and optimization techniques.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Expertise in frontend development workflows using Yarn, Webpack, micro-frontends architecture, NextJS, and TypeScript.
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>-</strong> Dedication to clean, robust, and scalable code practices, optimizing frontend performance.
                        </span>
                      </li>
                    </ul>
                    <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Lifestory Vietnam</h2>
                    <p className="font-semibold">
                      Senior Software Developer
                    </p>
                    <p className="font-semibold">
                    July 2017 - September 2021, Ho Chi Minh City
                    </p>
                    <p className="mt-6">During my tenure at Lifestory Vietnam as a Senior Software Developer, I crafted readable, maintainable code and led initiatives to streamline application functionalities, focusing on user-friendly solutions and code sustainability.</p>
                    <p className="mt-6">Notable Achievements:</p>
                    <p className="mt-6">
                      Conducting comprehensive code reviews, ensuring adherence to standards and facilitating future modifications.<br />
                      Proactively identifying and addressing application code-related issues, contributing to improved user documentation.<br />
                      Translating design concepts into reusable code and maintaining detailed solution documentation.<br />
                    </p>
                    <ul className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        <span>
                          <strong>
                            <a href="https://recipe.bom.co.kr" target='_blank' rel="noreferrer">recipe.bom.co.kr</a>
                          </strong>
                          <p>Languages: PHP (Laravel), JavaScript (ReactJS)</p>
                          <p>
                          Developed a responsive UI and implemented key functions like Login and Register based on design specifications.
                          
                          </p>
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>E-commerce Platforms: <a href="https://babosarang.co.kr" target='_blank' rel="noreferrer">babosarang.co.kr</a>, <a href="https://store.bom.co.kr" target='_blank' rel="noreferrer">store.bom.co.kr</a></strong>
                          <p><span className="font-semibold">Languages:</span> PHP (Godomall framework)</p>
                          <p>
                            Led the development and maintenance of event pages and shopping functionalities.
                          </p>
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong><a href="https://find.bom.co.kr" target='_blank' rel="noreferrer">find.bom.co.kr</a></strong>
                          <p><span className="font-semibold">Languages:</span> PHP (Codeigniter)</p>
                          <p>
                            Spearheaded the development of the find.bom.co.kr website.
                          </p>
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>Data Crawler and Macro Program Development</strong>
                          <p><span className="font-semibold">Languages:</span> NodeJS & Selenium, C#</p>
                          <p>
                            Designed and developed integrated crawler programs, enhancing data retrieval efficiency.
                          </p>
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong>Webis Alarm</strong>
                          <p><span className="font-semibold">Languages:</span> C# (WinForm with CefSharp), HTML, Javascript, css (For UI)</p>
                          <p>
                            Created a web-based alarm program for receiving task management notifications.
                          </p>
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <span>
                          <strong><a href="https://cheat.vn" target='_blank' rel="noreferrer">cheat.vn</a> - Scammer Report Website</strong>
                          <p><span className="font-semibold">Languages:</span> Frontend - Typescript (ReactJs), Redux; Backend - Nodejs (Strapi)</p>
                          <p>
                            Developed desktop and mobile layouts, established API connections, and customized Strapi plugins for improved API services.
                          </p>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    )
}

export default Homepage
