import { useStore } from "../../context"
import { dispatchKeyword } from "../../context/middleware"

export interface ISkillItemProps {
    key: string
    label: string
    isLockAssign?: boolean
}

const SkillItem = (props: ISkillItemProps) => {
    const [_, dispatch] = useStore((store) => store.currentPage)
    
    const { label, isLockAssign } = props
    const searchKeyword = label.toLowerCase()

    const onClickProject = (e: React.MouseEvent, keyword: string) => {
        if (!isLockAssign) {
            return
        }
        e.preventDefault()
        dispatchKeyword(keyword, dispatch)
    }

    return (
        <a
            href={`/projects?skill=${searchKeyword}`}
            onClick={(e) => onClickProject(e, searchKeyword)}
            className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-1">
                {label}
        </a>
    )
}

export default SkillItem
