import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const TpnrpLuckyWheel = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'esx-tpnrp-luckywheel')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">TPNRP Lucky Wheel - FiveM resources</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                The TPNRP Lucky Wheel, available as a FiveM resource, enhances the gaming experience on FiveM servers by seamlessly integrating with the default Lucky Wheel in GTA 5.

                            </p>
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/esx_tpnrp_luckywheel/screenshot.jpg')}
                                    alt="Screenshot"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                Crafted with expertise using a diverse tech stack that includes HTML, CSS, Javascript, Lua, and MySQL, this resource brings an engaging and interactive element to gameplay.<br /><br /> The project, hosted on GitHub at <a className="text-indigo-600" href='https://github.com/Sn0wBiT/esx_tpnrp_luckywheel' target='_blank' rel="noreferrer">https://github.com/Sn0wBiT/esx_tpnrp_luckywheel</a>, has garnered attention with <b>27 stars</b> and <b>55 forks</b>, attesting to its popularity and community support since its inception on August 27, 2019.
                            </p>
                            <br />
                            <div>
                                <video className="rounded-xl" controls>
                                    <source src={require('../../assets/projects/esx_tpnrp_luckywheel/demo_video.mp4')} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                            Explore the repository to discover the intricacies of this feature-rich FiveM resource and contribute to its thriving community.
                            </p>
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <img
                            src={require('../../assets/projects/esx_tpnrp_luckywheel/lua_client.png')}
                            alt="Client Side Lua"
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TpnrpLuckyWheel
