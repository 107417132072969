import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const VscodeVnwExtension = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'vscode-vietnamworks-extension')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">VietnamWorks Extension for VSCode</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                The VietnamWorks VSCode Extension harnesses the capabilities of the GitLab API. With its intuitive interface, it provides three key functionalities.
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                Firstly, it effortlessly lists all merge requests assigned to the user.
                                <br /><br />
                                Secondly, it empowers users to swiftly create merge requests from development to release branches across multiple projects, reducing manual effort and saving valuable time.
                                <br /><br />
                                Thirdly, it facilitates the quick generation of merge requests from the current working branch to the development branch, fostering seamless collaboration and code integration.
                            </p>
                            
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                This extension significantly enhances productivity and efficiency in every GitLab-driven task.
                            </p>
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/vscode-vietnamworks-extension/screenshot_2.png')}
                                    alt="Quickly create merge request from current working branch to develop"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                            <br />
                            
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <img
                            src={require('../../assets/projects/vscode-vietnamworks-extension/screenshot_1.png')}
                            alt="VietnamWorks VSCode Extension"
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={2432}
                            height={1442}
                        />
                        <br />
                        <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                        Why create a new extension when there's already an official one provided by GitLab? 
                        <br /><br />
                        Well, the official GitLab extension requires version 16.3 or higher, but my company is still using version 14.10, making that extension inaccessible to us. Additionally, another compelling reason is the need to quickly view and open merge requests from other developers. Given my role, I'm responsible for reviewing and merging requests from other developers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VscodeVnwExtension
