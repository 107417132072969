import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const SkingekiNoKyojin = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'shingeki-no-kyojin-fan-game')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{project.title}</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                            Experience the thrill of battling titans in this action-packed multiplayer game inspired by the iconic anime Attack on Titan (Shingeki No Kyojin). 
                            <br />
                            <br />
                            Developed using Unity3D in C# and powered by Photon for seamless online play, this game immerses you in the high-stakes world of humanity’s last stand against the towering titans. Join forces with other players, master the use of ODM gear, and fight to protect the walls from annihilation!
                            </p>
                            <br /><br />
                            <div>
                                <img
                                    src={require('../../assets/projects/shingeki-no-kyojin-fan-game/Youtube_stats.png')}
                                    alt="Product detail screen"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                            </div>
                            <br />
                            <div>
                            
                            </div>
                            <br />
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <iframe 
                            width="560" 
                            height="315"
                            src="https://www.youtube.com/embed/puf3t_Rr6R4?si=Gu17fW_pf_3wYQbo"
                            title="Shingeki No Kyojin Fan game"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkingekiNoKyojin

