import { useStore } from "../../../../context"
import { dispatchKeyword } from "../../../../context/middleware"

const SearchInput = () => {
    const [keyword, dispatch] = useStore((store) => store.keyword)
    
    return (
        <div className="relative mt-2 rounded-md shadow-sm mb-4">
            <input
                type="text"
                name="keyword"
                id="keyword"
                value={keyword}
                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Skill name or library name"
                onChange={(e) => dispatchKeyword(e.target.value, dispatch)}
            />
        </div>
    )
}

export default SearchInput
