import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const WebisAlarm = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'webis-alarm')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{project.title}</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                Webis Alarm is an application that runs on Windows 8, 8.1, 10, and 11. This program is a refactored version with a better user interface for our Webis Alarm application. It is used to receive tasks from the Korea Headquarters.
                            </p>
                            <br />
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                It was built on top of a C# WinForms application supported by CefSharp (CefSharp is a Chromium-based web browser). The program is developed using C# with CefSharp, and HTML, CSS, and JavaScript for the frontend UI. This program communicates with the backend using API endpoints from the Korea Headquarters.
                            </p>
                            <br />
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/webis_alarm/screenshot_ui_code.png')}
                                    alt="Screenshot"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                                <p className="text-gray-600 text-center">Javascript code.</p>
                            </div>
                            <br />
                            <br />
                            <div>
                                <img
                                    src={require('../../assets/projects/webis_alarm/screenshot_csharp_code.png')}
                                    alt="Screenshot"
                                    className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                                    width={2432}
                                    height={1442}
                                />
                                <p className="text-gray-600 text-center">C# code.</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <img
                            src={require('../../assets/projects/webis_alarm/screenshot.png')}
                            alt="UI"
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebisAlarm
