import { contents } from "../contents"

export type TTechInfo = {
    name: string
    projectCount: number
}

export const getTechStack = () => {
    const response: TTechInfo[] = []

    for (let i = 0; i < contents.length; i++) {
        const contentItem = contents[i]
        for (let j = 0; j < contentItem.techStack.length; j++) {
            const techName = contentItem.techStack[j]
            const index = response.findIndex((item) => item.name === techName)
            if (index >= 0) {
                response[index].projectCount = response[index].projectCount + 1
            } else {
                response.push({
                    name: techName,
                    projectCount: 1
                })
            }
        }
    }
    response.sort((a, b) => {
        if (a.projectCount > b.projectCount) {
            return -1
        } else {
            return 1
        }
    })
    return response
}