import { contents } from ".."
import SkillItem from "../../components/SkillItem"

const SpecialForce2Clone = () => {
    const filteredProjects = contents.filter((item) => item.slug === 'special-force-2-clone-game')
    const project = filteredProjects[0]

    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32 rounded-md">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{project.title}</h2>
                            <div className="my-4">
                                {project.techStack.map((item, index: number) => {
                                    return (
                                        <SkillItem key={`landl-index-${index}-${item}`} label={item} />
                                    )
                                })}
                            </div>
                            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                                Special Force 2 is a game developed by Dragonfly GF Co., LTD. I reverse-engineered the game, which was made with Unreal Engine 3. The entire 3D models and sounds are reusable.
                                <br />
                                <br />
                                The UI is based on the Squad game by VTC (you can see the UI in this video: <a href="https://www.youtube.com/watch?v=cqQSdD_AICk" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=cqQSdD_AICk</a>). I developed the entire code in C# using Unity 3D, with a lot of support from online tutorials.
                            </p>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="lg:pt-4">
                        <iframe src="https://drive.google.com/file/d/1X3uWpmYuxBQJVn4OHkrQvnEWgy6Kskwc/preview" width="100%" height="315" allow="autoplay" title="Special Force 2 - Clone game" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecialForce2Clone

