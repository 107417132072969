import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import ErrorPage from './pages/Error'
import Homepage from './pages/Homepage'
import ResumePage from './pages/Resume'
import ProjectPage from './pages/Projects'
import ProjectDetail from './pages/Projects/components/ProjectDetail'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Homepage />
      },
      {
        path: '/projects',
        element: <ProjectPage />
      },
      {
        path: '/resume',
        element: <ResumePage />
      },
      {
        path: '/project/:projectSlug',
        element: <ProjectDetail />
      }
      // {
      //   path: '/contacts',
      //   element: <>Contact Detail</>
      // }
    ]
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

